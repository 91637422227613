/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import doubleEdgeSword from "../images/ai-double-edge.png";
import aiHumanCommunication from "../images/ai-humans-communication.png";
import aiMultiSensorTechnology from "../images/ai-multi-sensor-technology.jpg";
import aiBoomBloatInternet from "../images/ai-content-boom-will-quality-drown-in-a-sea-of-quantity.jpg";
import aiRecursiveKnowledge from "../images/ai-recursive-knowledge.png";
import jasonAusbornImage from "../images/jason_ausborn_dp.jpg";
import { AiSolutionsData, Blog, JobType, NaicsEntry } from "./Types";

/**
 * This is a root component.
 *
 * Date: 22th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */

// image width should be 1035px
export const blogsState: Blog[] = [
  {
    "blog-id": "ai-knowledge-recursion",
    title: "AI Knowledge Recursion: A Potential Loophole in Machine Learning?",
    shortDescription:
      "AI learning from itself? Great, but is it learning the whole story? Discover how AI Knowledge Recursion can impact results.",
    imageLink: aiRecursiveKnowledge,
    imageAltText: "",
    author: "Jason Ausborn",
    authorPicture: jasonAusbornImage,
    designation: "Founder - CEO",
    at: "2024-04-21",
    type: ["AI", "Machine Learning"],
    content: [
      "The integration of Artificial Intelligence (AI) has permeated various industries, streamlining processes and generating valuable insights. However, as AI systems process and generate information, a potential concern arises: AI Knowledge Recursion. This phenomenon occurs when the outputs from AI models are fed back into the system as training data, creating a loop where the same data set informs future iterations. While it appears like a self-improvement mechanism, this recursion could introduce biases and hinder the true potential of AI.",
      "Amplification of Bias:  AI systems learn from the data they are trained on. If biased data is fed into the system, the AI will amplify those biases in subsequent outputs. When feeding back AI-generated data, these pre-existing biases can become entrenched, leading to skewed results and potentially discriminatory outcomes.  For instance, an AI system tasked with recruiting may unconsciously favor certain demographics based on historical hiring patterns within the company.",
      "Echo Chambers and Limited Knowledge Acquisition:  Recurring data creates a closed loop, limiting the AI's exposure to new information and perspectives. This can lead to the formation of 'echo chambers' where the AI reinforces the same concepts without expanding its knowledge base.  Imagine an AI system analyzing customer reviews. If only positive reviews are fed back, the system might struggle to identify and address customer pain points.",
      "Reduced Accuracy and Generalizability:   Overreliance on recycled data can hinder the AI's ability to adapt to new situations. The model may become overly specialized on the specific data set, leading to inaccurate results when presented with real-world scenarios with subtle variations.  For example, an AI system trained on weather data from a specific location might perform poorly when analyzing data from a drastically different climate zone.",
      "AI Knowledge Recursion holds immense potential for refining and accelerating AI development. However, a cautious approach is necessary to mitigate potential pitfalls. Strategies like incorporating  diverse data sets, employing human oversight in training loops, and implementing continuous monitoring of AI outputs can minimize bias and ensure the generalizability of AI models. As AI becomes more ingrained in our lives, fostering responsible development practices is crucial to unlocking its true potential and promoting fair and accurate AI applications.",
    ],
  },
  {
    "blog-id": "human-ai-symbiosis",
    title: "Human-AI Symbiosis: Will AI Reshape How We Communicate?",
    shortDescription:
      "As AI integrates into part of our lives, a captivating question arises: Will our interactions with AI  influence the way we communicate?",
    imageLink: aiHumanCommunication,
    imageAltText: "",
    author: "Jason Ausborn",
    authorPicture: jasonAusbornImage,
    designation: "Founder - CEO",
    at: "2024-04-28",
    type: ["AI", "Humans"],
    content: [
      "The rise of Artificial Intelligence (AI) has fundamentally changed how we interact with technology. As we become increasingly reliant on AI tools for tasks ranging from information retrieval to creative content generation, a fascinating question emerges: how will this human-AI partnership influence how we communicate with each other? Will our interactions with AI seep into our natural language communication, leading to a potential evolutionary shift?",
      "Precision and Efficiency: AI excels at processing vast amounts of data and identifying patterns. As we learn to tailor our communication with AI systems to achieve optimal results – using clear, concise language with minimal ambiguity – these habits may transfer to our natural conversations. We might become more focused on conveying information precisely, potentially leading to a communication style that prioritizes efficiency over nuance.",
      "Evolving Nuance and Understanding:  However, human communication thrives on more than just factual accuracy.  AI's limitations in understanding subtle cues like sarcasm, empathy, and humor could push humans to become more adept at expressing these nuances verbally.  We may become more conscious of nonverbal cues like body language and tone to ensure our messages are interpreted correctly, both with AI and with fellow humans.",
      "Collaborative Communication:  AI's strength lies in its ability to analyze and synthesize information. As we collaborate with AI on tasks and problem-solving, we might adopt a more collaborative communication style, focusing on shared goals and clear information exchange. This could lead to more structured discussions and a heightened ability to break down complex concepts into clear, actionable steps.",
      "The human-AI partnership holds immense potential to transform communication.  While AI might influence us to prioritize precision and efficiency, it can also push us to refine our ability to express those elusive human qualities that AI currently struggles with. The future of communication may lie in a synergy – a blend of AI's analytical prowess with our human capacity for empathy, nuance, and collaboration. This co-evolution could lead to a communication landscape that is both more precise and more richly human.",
    ],
  },
  {
    "blog-id": "the-double-edged-sword-of-ai-dependency",
    title:
      "The Double-Edged Sword of AI Dependency: Managing Workload in the Age of Automation",
    shortDescription:
      "AI automation is streamlining industries, but it also creates a critical challenge: AI dependency.",
    imageLink: doubleEdgeSword,
    imageAltText: "",
    author: "Jason Ausborn",
    authorPicture: jasonAusbornImage,
    designation: "Founder - CEO",
    at: "2024-05-05",
    type: ["AI", "Humans", "Automation"],
    content: [
      "The relentless march of Artificial Intelligence (AI) automation is transforming industries, streamlining tasks, and replacing human labor. While this promises increased efficiency and productivity, it also introduces a new challenge: AI dependency. As companies rely more heavily on AI systems, a smaller core of AI engineers becomes responsible for their development, implementation, and maintenance. This raises a critical question: how can we manage the workload of AI engineers to ensure the smooth operation and continuous improvement of these automated systems?",
      "Specialization and Collaboration:  The expanding scope of AI applications necessitates specialization within the AI engineering team.  Experts can focus on specific areas like machine learning, natural language processing, or computer vision. However, effective collaboration remains key.  Regular communication and knowledge sharing between specialists ensures a holistic understanding of the AI ecosystem and facilitates troubleshooting complex issues.",
      "Standardization and Pre-built Solutions:  The development of standardized AI frameworks and pre-built solutions can significantly reduce workload. These pre-existing tools can handle routine tasks, freeing up engineers to focus on more complex challenges like customization and integration of AI systems within the company's specific workflow.",
      "Automation for AI Engineers:  Ironically, AI itself can be a valuable tool for managing the workload of AI engineers.  Automating repetitive tasks like data pre-processing, model monitoring, and anomaly detection can free up valuable time for engineers to focus on higher-level problem-solving and strategic planning.",
      "Metrics and Monitoring:  Proactive monitoring of AI systems is crucial for identifying and addressing potential issues before they escalate. Implementing clear performance metrics allows engineers to track the effectiveness of AI models and prioritize optimization efforts.",
      "The growing reliance on AI presents both opportunities and challenges for companies. By embracing specialization, leveraging pre-built solutions, and utilizing automation for AI tasks themselves, companies can empower their AI engineers to effectively manage the ever-increasing workload.  Furthermore, establishing clear performance metrics and continuous monitoring practices  will ensure the smooth operation and continuous evolution of AI systems,  maximizing the potential of this transformative technology.",
    ],
  },
  {
    "blog-id": "how-multisensory-technology-is-reshaping-the-world",
    title:
      "The Future of AI: How Multisensory Technology is Reshaping the World",
    shortDescription:
      "As AI mimics human senses to capture more input data, get ready for a revolution in machines and how they impact our world.",
    imageLink: aiMultiSensorTechnology,
    imageAltText: "",
    author: "Jason Ausborn",
    authorPicture: jasonAusbornImage,
    designation: "Founder - CEO",
    at: "2024-05-15",
    type: ["AI", "Humans", "Future"],
    content: [
      'The field of Artificial Intelligence (AI) is rapidly evolving, with researchers constantly pushing the boundaries of what machines can perceive and understand. One particularly exciting area of development focuses on equipping AI with the ability to process information from multiple sensory channels, mimicking the way humans experience the world. This "multisensory" approach promises to revolutionize AI capabilities and significantly impact our lives in the coming years.',
      'The Power of Many Senses:  Currently, most AI systems rely heavily on visual data. While impressive advancements have been made in computer vision, it\'s only one piece of the puzzle.  Imagine an AI that can not only "see" an object but also analyze its texture through touch simulation, or understand its composition through simulated smell or taste sensors. This multisensory data would allow AI to develop a far richer and more nuanced understanding of the world, enabling more accurate decision-making and interaction with the environment.',
      "Unlocking Potential Across Industries:  The applications of multisensory AI are vast. In healthcare, AI could analyze medical scans with a deeper understanding of tissue composition, leading to more accurate diagnoses. In robotics, robots equipped with multisensory capabilities could perform complex tasks in dynamic environments, such as search and rescue operations or delicate surgery.  Even in our everyday lives, AI-powered assistants could become more intuitive and helpful, understanding not only our words but also our gestures and emotional cues.",
      "Challenges and Considerations:  Developing and integrating multisensory AI technology presents several challenges.  Creating accurate and reliable artificial sensors remains a complex task. Additionally, processing and interpreting vast amounts of multisensory data requires significant computational power and sophisticated algorithms. Furthermore, ethical considerations regarding privacy and potential misuse of such technology need to be carefully addressed.",
      "The incorporation of multisensory capabilities in AI represents a significant leap forward in machine intelligence. While challenges remain, the potential benefits are undeniable. As AI continues to evolve and integrate these new sensory inputs, we can expect to see a wave of innovation that will transform various aspects of our lives.  The future holds exciting possibilities for more intelligent, adaptable, and human-like AI, opening doors to a world of enhanced experiences and groundbreaking advancements across various sectors.",
    ],
  },
  {
    "blog-id": "ai-content-boom-will-quality-drown-in-a-sea-of-quantity",
    title: "AI Content Boom: Will Quality Drown in a Sea of Quantity?",
    shortDescription:
      "The rise of AI content creation promises a surge of information online, but are we at risk of sacrificing quality for sheer volume?",
    imageLink: aiBoomBloatInternet,
    imageAltText: "",
    author: "Jason Ausborn",
    authorPicture: jasonAusbornImage,
    designation: "Founder - CEO",
    at: "2024-07-12",
    type: ["AI", "Internet", "Future"],
    content: [
      "This article explores the potential impact of generative Artificial Intelligence (AI) on content creation within the digital landscape. We delve into the concern of a content saturation on the internet, where automation might prioritize quantity over quality.  Furthermore, the article investigates the challenges of discerning truth from misinformation within this burgeoning content ecosystem. We explore a potential scenario where large corporations leverage this information overload to create segmented channels of information, potentially mirroring the structure of cable TV packages.",
      "The emergence of generative AI signifies a revolutionary shift in content creation.  These AI tools have the potential to streamline content production, allowing creators to focus on strategy and refinement. However, a crucial question arises:  Will the sheer volume of AI-generated content overshadow the importance of quality and authenticity?",
      "Imagine a future internet inundated with content, where quantity reigns supreme.  Consumers might struggle to differentiate between well-researched, informative pieces and superficial, AI-generated content. This raises concerns about the erosion of trust and the ability to discern fact from fiction.",
      "Furthermore, the potential for content creators to become detached from the content itself poses a challenge.  Over-reliance on AI for content generation could lead to a situation where creators prioritize automation over  the inherent value and purpose of the content.  In turn, this raises the question:  if creators themselves are not invested in the message, will consumers find the content engaging or meaningful?",
      "The impending content deluge driven by AI necessitates a critical discussion about quality and responsible content creation.  It's crucial for creators to leverage AI as a tool to enhance the creative process, not replace it entirely.  The human element – the ability to curate, analyze, and inject meaning –  remains vital to fostering a trustworthy and informative online environment. Moreover, consumers must develop a more discerning eye when navigating the vast digital ocean.  Critical thinking skills and an awareness of potential bias will be essential tools for evaluating online content. Ultimately, the internet's future hinges on striking a balance between automation and human curation.  By embracing AI as a collaborative force and prioritizing quality information, we can ensure a dynamic and informative online world for generations to come.",
    ],
  },
].sort((a: Blog, b: Blog) => {
  const dateA = new Date(a.at);
  const dateB = new Date(b.at);

  if (dateA > dateB) {
    return -1;
  } else if (dateA < dateB) {
    return 1;
  } else {
    return 0;
  }
});

export const aiSolutionsState: AiSolutionsData[] = [
  {
    divId: "intuitive-ai",
    image:
      "https://images.unsplash.com/photo-1545697729-0ab8f5b1954c?q=80&h=1442&w=2432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Intuitive AI Tools",
    description:
      "Frustrated by the technical roadblocks to using AI? Existing tools often demand advanced coding skills and hefty budgets, limiting many who can benefit.  Our platform brings Intuitive AI Tool creation and usage to life.",
    bulletPoints: [
      {
        name: "Build Your Own AI Vision: ",
        description:
          "Have a unique idea for an AI solution? Our platform empowers you to create custom tools from scratch.  No coding experience required!",
      },
      {
        name: "Effortless Customization: ",
        description:
          "Our intuitive interface makes fine-tuning AI tools easier. By having control over your AI Tool settings, you can personalize tools for optimization and reusability.",
      },
      {
        name: "Master the Art of AI Design: ",
        description:
          "Learning to build your own AI tools is easier than ever.  We offer comprehensive training resources and tutorials to guide you through the process, step-by-step.",
      },
      {
        name: "Leverage Pre-Built Tools: ",
        description:
          "Don't reinvent the wheel! Our platform offers a vast library of ready-made AI tools, built by our community and vetted for quality. Find the perfect tool for your needs and get started instantly.",
      },
      {
        name: "A Platform for All: ",
        description:
          "Whether you're a seasoned developer or a complete AI novice, our platform is designed to be accessible and valuable for everyone.  Create or utilize AI tools to unlock your full potential.",
      },
    ],
  },
  {
    divId: "ai-marketplace",
    image:
      "https://images.unsplash.com/photo-1501523460185-2aa5d2a0f981?q=80&h=1442&w=2432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "AI Marketplace",
    description:
      "The rise of AI is inevitable, but what about the experts whose skills it may impact?  The challenge lies in capturing the knowledge of those being displaced by AI and integrating it into the future of this technology.  This expertise, built over years, is invaluable and shouldn't be lost.",
    bulletPoints: [
      {
        name: "Monetize Your Knowledge:  ",
        description:
          "Don't let your skills go to waste!  The AI Marketplace provides a platform to build and sell custom AI tools, generating passive income and capitalizing on your unique knowledge base.",
      },
      {
        name: "Empowering Experts: ",
        description:
          "Are you a seasoned professional or business with a wealth of knowledge?  Our AI Marketplace allows you to transform your expertise into next-generation AI tools.  Share your valuable insights and become a leader in the AI revolution.",
      },
      {
        name: "Contribute to the Future: ",
        description:
          "The power of AI is shaped by the expertise we bring to it.  By contributing to the AI Marketplace, you're not just creating tools, you're shaping the future of this transformative technology.",
      },
      {
        name: "Transition into the Future of Work: ",
        description:
          "The rise of AI doesn't have to mean being left behind.  The AI Marketplace offers a smooth transition  by allowing you to adapt your skills and work directly with AI technologies.",
      },
      {
        name: "Prevent Knowledge Loss: ",
        description:
          "The wisdom of experienced professionals is invaluable.  The AI Marketplace ensures this knowledge isn't lost by  providing a platform to capture and integrate it  into the future of AI.",
      },
    ],
  },
  {
    divId: "true-data-control",
    image:
      "https://images.unsplash.com/photo-1640158615573-cd28feb1bf4e?q=80&h=1442&w=2432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "True Data Control",
    description:
      "Ever worried about your data being trapped within an AI tool?  Many AI services make it difficult, if not impossible, to remove your data once it's uploaded. This lack of control raises serious privacy concerns.",
    bulletPoints: [
      {
        name: "Your Data, Your Choice:  ",
        description:
          "You are the sole owner of your data on our platform. Access and store history for reference, or completely remove your data from our systems.",
      },
      {
        name: "Complete Transparency: ",
        description:
          "We provide clear information on how your data is used and stored. By providing transparency, you can make sound decisions on the handling of your data.",
      },
      {
        name: "Effortless Data Removal: ",
        description:
          "Remove your data entirely from our servers at any time with just a few clicks. Just remember, it will be gone and unretrievable by us.",
      },
      {
        name: "Privacy You Can Trust: ",
        description:
          "Focus on your work, not your worries. True Data Control ensures your privacy is always respected.",
      },
    ],
  },
  {
    divId: "reason-driven-ai",
    image:
      "https://images.unsplash.com/photo-1713345248737-2698000f143d?q=80&h=1442&w=2432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&fit=crop&w=2830&q=80&blend=f4f2fc&sat=-100&exp=7&blend-mode=multiply",
    title: "Reason-Driven AI",
    description:
      "Frustrated by AI results that come out of a black box?  Traditional AI tools often leave users in the dark about how conclusions are reached, creating a lack of trust and hindering effective collaboration.  This opacity makes it difficult to understand the reasoning behind AI-generated content.",
    bulletPoints: [
      {
        name: "Unveiling the Why:  ",
        description:
          'Forget cryptic outputs. Our platform provides clear explanations alongside every AI-generated result. No more wondering "how did it get that answer?". Gain a step-by-step understanding of the reasoning process,  laid out in easy-to-follow language.',
      },
      {
        name: "Trustworthy  Insights: ",
        description:
          "Reason-Driven AI fosters a deep sense of trust in your AI tools. By understanding the logic behind each result, you gain confidence in their accuracy and validity. This transparency empowers you to rely on AI outputs  for critical decision-making.",
      },
      {
        name: "Actionable Intelligence: ",
        description:
          'Transparency isn\'t just about understanding the "how."  Reason-Driven AI equips you with  "actionable intelligence."  By seeing the clear path the AI took to reach its conclusions, you can identify key factors and trends within your data. This empowers you to make informed decisions and take strategic actions based on the insights revealed by AI tools.',
      },
      {
        name: "Humans and AI Collaboration: ",
        description:
          "Reason-driven AI isn't just about trusting the AI, it's about working together. Clear explanations open a  dialogue between humans and AI. You can identify potential biases or areas for improvement in the AI's reasoning, allowing you to provide feedback and refine the decision-making process over time. This fosters a collaborative environment where humans and AI  work together to achieve optimal results.",
      },
    ],
  },
  {
    divId: "ai-upskilling",
    image:
      "https://images.unsplash.com/photo-1455849318743-b2233052fcff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "AI Upskilling for Displaced Workers",
    description:
      "The tide of AI is rising exponentially, and some workers may feel swept away. Our platform offers AI Upskilling for Displaced Workers. We provide individuals and businesses impacted by AI an opportunity to thrive.",
    bulletPoints: [
      {
        name: "Smoother Transition, Brighter Future:  ",
        description:
          "Our AI Upskilling equips you with the necessary skills and platform to  work seamlessly alongside AI, not against it.  Learn how to build and utilize powerful AI tools, ensuring a smooth transition into a future powered by intelligent automation.",
      },
      {
        name: "Monetize Your Expertise:  ",
        description:
          "Years of experience shouldn't be left behind. AI Upskilling empowers you to  transform your valuable knowledge into custom AI tools.  Sell them within our AI Marketplace,  generating income and capitalizing on your unique skillset within the booming AI economy.",
      },
      {
        name: "Knowledge Preservation:  ",
        description:
          "Don't let decades of expertise vanish!  Our program allows you to  capture and integrate your knowledge into the future of AI.  This not only preserves valuable insights but also empowers you to become a leader in shaping the evolution of AI technology.",
      },
    ],
  },
];

export const jobOpeningsState: JobType[] = [
  {
    title: "Full-Stack Developer",
    description:
      "Do you thrive in fast-paced environments and enjoy building innovative products? Are you passionate about artificial intelligence and its potential to revolutionize industries? If so, then we want to hear from you!",
    jobType: "Full-time",
    location: "Remote | Denver Metro, USA",
  },
  {
    title: "Technical Lead (AI)",
    description:
      "Nerd Toolbox is on a mission to Empowering innovation with responsible AI. We build ethical, transparent AI tools that put users in control of their data. We're looking for a passionate and experienced Technical Lead (AI) to join our growing team.",
    jobType: "Full-time",
    location: "Remote | Denver Metro, USA",
  },
].sort((a, b) => a.title.localeCompare(b.title));

export const naicsCodes: NaicsEntry[] = [
  { code: "541511", title: "Custom Computer Programming Services" },
  { code: "541512", title: "Computer Systems Design Services" },
  { code: "541519", title: "Other Computer Related Services" },
  { code: "518210", title: "Data Processing, Hosting, and Related Services" },
  { code: "541330", title: "Engineering Services" },
  {
    code: "541611",
    title:
      "Administrative Management and General Management Consulting Services",
  },
  {
    code: "541690",
    title: "Other Scientific and Technical Consulting Services",
  },
  {
    code: "541715",
    title:
      "Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)",
  },
  {
    code: "541720",
    title: "Research and Development in the Social Sciences and Humanities",
  },
  { code: "561410", title: "Document Preparation Services" },
  {
    code: "541990",
    title: "All Other Professional, Scientific, and Technical Services",
  },
].sort((a, b) => a.code.localeCompare(b.code));
