/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import { Link } from "react-router-dom";
import ScrollToTop from "../../common/ScrollToTop";
import anonymousProfile from "../../images/anonymous_profile.png";
import jasonDp from "../../images/jason_ausborn_dp.jpg";
import { naicsCodes } from "../../common/InitialState";

/**
 * This is a root component.
 *
 * Date: 12th July 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */
const WhatWeDo = () => {
  return (
    <div className="bg-[url('https://images.unsplash.com/photo-1626908013351-800ddd734b8a?q=80&w=1372&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&fit=crop&w=2830&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply')]">
      <main className="relative isolate">
        <ScrollToTop />
        <div
          className="absolute inset-x-0 flex justify-center overflow-hidden top-4 transform-gpu blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
            style={{
              clipPath:
                "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
            }}
          ></div>
        </div>

        <div className="pt-10 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img
            loading="lazy"
            src="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className="aspect-[9/4] w-full object-cover xl:rounded-3xl"
          />
        </div>
        <div className="px-6 mx-auto mt-20 max-w-7xl sm:mt-3 lg:px-8">
          <div className="w-full mx-auto md:mx-6 lg:mx-0">
            <p className="mt-4 text-lg leading-8 text-gray-300">
              Empowering innovation through collaborative AI-driven solutions.
            </p>
          </div>
          <div className="px-6 my-10 border-t lg:px-8 border-white/50"></div>
          <div className="pb-6 mx-auto lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              What we do?
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              <span className="font-semibold">Nerd Toolbox</span> is a
              cutting-edge technology company specializing in the design and
              development of innovative AI-powered software solutions and expert
              systems. We cater to both businesses and government agencies,
              offering a wide range of services including custom application
              development, complex system architecture, and advanced AI
              integration.
            </p>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              Our expertise spans from creating efficient logistics platforms
              and natural language processing tools to developing automated
              trading systems and government expert systems. With over 25 years
              of experience, we excel in solving complex problems, improving
              operational efficiency, and driving digital transformation across
              various sectors. Our capabilities encompass cloud computing,
              database design, multi-tier architecture development, and
              pioneering work in artificial intelligence. We're committed to
              delivering high-quality, tailored solutions that meet the unique
              needs of each client, whether it's a startup looking for rapid
              application development or a government agency requiring
              sophisticated expert systems.
            </p>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              The table below outlines the NAICS codes that best represent our
              diverse range of services and expertise, highlighting our ability
              to serve both public and private sector clients across multiple
              domains of technology and consulting.
            </p>
            <div className="flow-root mt-8">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-700">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                        >
                          NAICS Code
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-800">
                      {naicsCodes.map((naicsCode) => (
                        <tr key={naicsCode.code}>
                          <td className="py-4 pl-4 pr-3 text-sm font-medium text-white whitespace-nowrap sm:pl-0">
                            {naicsCode.code}
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-300">
                            {naicsCode.title}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              Additionally in-house, we're contributing to the future of AI
              accessibility. Our innovative Minimum Viable Product (MVP)
              democratizes AI technology, offering intuitive solutions for users
              of all skill levels to create, customize, and deploy AI tools
              without extensive coding. We're also launching an AI Marketplace
              feature where experts can monetize their knowledge by developing
              and selling custom AI tools. This initiative bridges the gap
              between traditional expertise and emerging AI technologies,
              preserving valuable industry knowledge in the digital transition.
              Our MVP reflects our commitment to making AI accessible, driving
              innovation, and creating new opportunities in the evolving
              landscape of work.
            </p>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              For more detailed information about our MVP focus, please visit
              our{" "}
              <Link
                to="/ai-solutions"
                className="font-bold bg-gradient-to-r from-[#7a5dee] to-[#ab80e3] text-transparent bg-clip-text"
              >
                AI Solutions
              </Link>{" "}
              page.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default WhatWeDo;
