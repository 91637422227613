/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import { useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import ScrollToTop from "./common/ScrollToTop";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Error404 from "./pages/404/Error404";
import AiSolutions from "./pages/ai-solutions/AiSolutions";
import BlogDetails from "./pages/blog/BlogDetails";
import BlogPosts from "./pages/blog/BlogPosts";
import Career from "./pages/career/Career";
import ContactUs from "./pages/contact-us/ContactUs";
import HomePage from "./pages/homepage/HomePage";
import JoinBeta from "./pages/join-beta/JoinBeta";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";
import TermsAndConditions from "./pages/terms/TermsAndConditions";
import WhoWeAre from "./pages/who-we-are/WhoWeAre";
import WhatWeDo from "./pages/WhatWeDo/WhatWeDo";

/**
 * This is a root component.
 *
 * Date: 20th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */

function App() {
  const [betaBanner, setBetaBanner] = useState<boolean>(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      {betaBanner && (
        <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <div
            className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            ></div>
          </div>
          <div
            className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            ></div>
          </div>
          <p className="text-sm leading-6 text-gray-900">
            <Link to="/join-beta-waitlist" className="text-sm">
              <strong className="font-semibold ">Nerd ToolBox AI</strong>
              <svg
                viewBox="0 0 2 2"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
                aria-hidden="true"
              >
                <circle cx="1" cy="1" r="1" />
              </svg>
              Be among the first to experience our platform. Join our wait-list
              and receive early beta access once launched.&nbsp;
              <span aria-hidden="true">&rarr;</span>
            </Link>
          </p>
          <div className="flex justify-end flex-1">
            <button
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
              onClick={() => {
                setBetaBanner(!betaBanner);
              }}
            >
              <span className="sr-only">Dismiss</span>
              <svg
                className="w-5 h-5 text-gray-900"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </button>
          </div>
        </div>
      )}
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/ai-solutions" element={<AiSolutions />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/what-we-do" element={<WhatWeDo />} />
        <Route path="/blog" element={<BlogPosts />} />
        <Route path="/blog/*" element={<BlogDetails />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/join-beta-waitlist" element={<JoinBeta />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
