/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { blogsState } from "../../common/InitialState";
import ScrollToTop from "../../common/ScrollToTop";

/**
 * This is a root component.
 *
 * Date: 22th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */

const BlogPosts = () => {
  useEffect(() => {
    document.title = "Blog";
  }, []);

  const blogs = blogsState;

  return (
    <div className="relative top-0 bg-white bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]">
      <ScrollToTop />
      <div className="px-4 pt-3 pb-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* <!-- Card Blog --> */}

        <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
          {/* <!-- Title --> */}
          <div className="mx-auto mb-10 text-center lg:mb-14">
            <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-black">
              Explore Our Blog: Insights on AI and Technology
            </h2>
            <p className="mt-1 text-gray-600 dark:text-neutral-500">
              Discover thought-provoking articles, practical tips, and the
              latest advancements in AI development.
            </p>
          </div>
          {/* <!-- End Title --> */}
          <div className="grid max-w-2xl grid-cols-1 mx-auto my-10 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {blogs.map((e, indx) => (
              <article
                key={(e.title + indx).toString()}
                className="flex flex-col items-start justify-between"
              >
                <Link to={`/blog/${e["blog-id"]}`}>
                  <div className="relative w-full">
                    <img
                      src={e.imageLink}
                      alt={e.imageAltText}
                      className="saturate-0 transition-transform duration-500 ease-in-out size-full start-0 group-hover:scale-105 aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                    />
                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
                  </div>
                </Link>
                <div className="max-w-xl">
                  <div className="flex items-center mt-8 text-xs gap-x-4">
                    <time dateTime={e.at} className="text-gray-500">
                      {new Date(e.at).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </time>
                    {e.type.map((e, i) => (
                      <p
                        key={(e + i).toString()}
                        className="hover:cursor-pointer relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                      >
                        {e}
                      </p>
                    ))}
                  </div>
                  <div className="relative group">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <Link to={`/blog/${e["blog-id"]}`}>
                        <span className="absolute inset-0"></span>
                        {e.title}
                      </Link>
                    </h3>
                    <p className="mt-5 text-sm leading-6 text-gray-600 line-clamp-3">
                      {e.shortDescription}
                    </p>
                  </div>
                  <div className="relative flex items-center mt-8 gap-x-4">
                    <img
                      src={e.authorPicture}
                      alt=""
                      className="w-10 h-10 bg-gray-100 rounded-full"
                    />
                    <div className="text-sm leading-6">
                      <p className="font-semibold text-gray-900">
                        <span className="absolute inset-0"></span>
                        {e.author}
                      </p>
                      <p className="text-gray-600">{e.designation}</p>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogPosts;
