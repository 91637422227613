/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import ScrollToTop from "../../common/ScrollToTop";

/**
 * This is a root component.
 *
 * Date: 22th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */

const TermsAndConditions = () => {
  return (
    <div className="static top-0 bg-white bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]">
      <ScrollToTop />
      <div className="px-4 pt-3 pb-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="mx-auto max-w-[40rem] prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600">
          <div className="text-center">
            <h1 className="pb-2 text-5xl font-extrabold leading-tight text-gray-900 font-heading tracking-px-n">
              Terms & Conditions
            </h1>
          </div>
          <div className="pt-10 text-base font-medium leading-relaxed text-gray-500">
            <p>
              These Terms of Service ("Terms") govern your access to and use of
              Nerd Toolbox's website located at{" "}
              <a
                href="https://nerdtoolbox.com"
                target="_blank"
                rel="noreferrer"
                className="text-sky-600 hover:text-sky-500"
              >
                (https://nerdtoolbox.com)
              </a>{" "}
              (the "Website").
            </p>
            <ol className="pt-2 list-decimal">
              <li>
                Acceptance of Terms
                <p className="pt-1">
                  By accessing or using the Website, you agree to be bound by
                  these Terms. If you disagree with any part of the Terms, then
                  you may not access or use the Website.
                </p>
              </li>
              <li className="pt-2">
                Modifications to Terms
                <p className="pt-1">
                  Nerd Toolbox may revise and update these Terms at its sole
                  discretion. You are responsible for checking the Terms
                  periodically for changes. Your continued use of the Website
                  following the posting of revised Terms means that you accept
                  and agree to the changes.
                </p>
              </li>
              <li className="pt-2">
                Use of Website
                <p className="pt-1">
                  The Website is provided for your personal, non-commercial use
                  only. You may not modify, copy, distribute, transmit, display,
                  perform, reproduce, publish, license, create derivative works
                  from, transfer, or sell any information, software, products,
                  or services obtained from the Website.
                </p>
              </li>
              <li className="pt-2">
                Intellectual Property
                <p className="pt-1">
                  The Website and its content, including but not limited to
                  text, graphics, logos, images, and software, are the property
                  of Nerd Toolbox and its licensors and protected by copyright
                  and other intellectual property laws.
                </p>
              </li>
              <li className="pt-2">
                User Content
                <p className="pt-1">
                  The Website may allow you to submit content, such as comments,
                  reviews, or other information ("User Content"). You are solely
                  responsible for all User Content that you submit to the
                  Website. You represent and warrant that you own all rights to
                  the User Content or have obtained all necessary permissions
                  and licenses to use and authorize us to use your User Content
                  in accordance with these Terms.
                </p>
              </li>
              <li className="pt-2">
                Disclaimer
                <p className="pt-1">
                  THE WEBSITE IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY
                  KIND, WHETHER EXPRESS OR IMPLIED. NERD TOOLBOX DISCLAIMS ALL
                  WARRANTIES, INCLUDING BUT NOT LIMITED TO, THE WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                  NON-INFRINGEMENT, AND SECURITY. NERD TOOLBOX DOES NOT WARRANT
                  THAT THE WEBSITE WILL BE AVAILABLE, UNINTERRUPTED, OR
                  VIRUS-FREE.
                </p>
              </li>
              <li className="pt-2">
                Limitation of Liability
                <p className="pt-1">
                  IN NO EVENT SHALL NERD TOOLBOX, ITS OFFICERS, DIRECTORS,
                  EMPLOYEES, OR AGENTS BE LIABLE FOR ANY DIRECT, INDIRECT,
                  INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES
                  (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
                  REVENUE, DATA, OR USE) ARISING OUT OF OR RELATING TO THE USE
                  OR INABILITY TO USE THE WEBSITE, EVEN IF NERD TOOLBOX HAS BEEN
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
              </li>
              <li className="pt-2">
                Termination
                <p className="pt-1">
                  Nerd Toolbox may terminate your access to the Website at any
                  time, for any reason, or no reason.
                </p>
              </li>
              <li className="pt-2">
                Governing Law
                <p className="pt-1">
                  These Terms shall be governed by and construed in accordance
                  with the laws of the State of Colorado, without regard to its
                  conflict of law provisions.
                </p>
              </li>
              <li className="pt-2">
                Entire Agreement
                <p className="pt-1">
                  These Terms constitute the entire agreement between you and
                  Nerd Toolbox regarding your use of the Website.
                </p>
              </li>
              <li className="pt-2">
                Contact Us
                <p className="pt-1">
                  If you have any questions about these Terms, please contact us
                  at{" "}
                  <a
                    href="mailto:admin@nerdtoolbox.com"
                    className="text-sky-600 hover:text-sky-500"
                  >
                    admin@nerdtoolbox.com
                  </a>
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsAndConditions;
