/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

export const companyName = "Nerd Toolbox";
export const logoName = "nerdtoolbox";
export const logoLink =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/0fdfb5d080b96223b9143186dd8d931ace0b750b32dcbce4770c90c22bb4429b?";

export const home = "Home";
export const whoWeAre = "Who we are?";
export const whatWeDo = "What we do?";
export const aiSolutions = "AI Solutions";
export const career = "Careers";
export const blog = "Blog";
export const contactUs = "Contact Us";

export const privacy = "Privacy";
export const terms = "Terms";
export const status = "Status";

export const phoneNumber = "(720) 829-0790";
export const contactEmail = "contact@nerdtoolbox.com";
export const contactAddress = "14980 E Pacific Pl, Aurora, CO, 80014";
