/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import { Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { JoinBetaForm } from "../../common/Types";
import cyborg from "../../images/cyborg.jpg";

/**
 * This is a root component.
 *
 * Date: 22th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */

const JoinBeta = () => {
  useEffect(() => {
    document.title = "Join Our Beta";
  }, []);
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .matches(
        /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,9})/g,
        "Please enter a valid email"
      ),
  });

  const onSubmit = async (values: JoinBetaForm, { resetForm }: any) => {
    await fetch(process.env.REACT_APP_BACKENDURL + "/email-waitlist", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((result) => {
        if (!result.ok) {
          toast.error(
            "Sorry, something has gone wrong. Please check the input and or try again later."
          );
          throw Error("Error, Server didn't able to process the request.");
        }
        return result.json();
      })
      .then((result) => {
        toast.success(result.data.message);
        resetForm();
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <div className="relative py-3 top-0 bg-white bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]">
      <div
        className="absolute inset-x-0 top-0 hidden h-1/2 bg-gray-50 lg:block"
        aria-hidden="true"
      ></div>
      <div className="mx-auto bg-violet-900 max-w-7xl lg:bg-transparent lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:bg-transparent lg:py-16">
            <div
              className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
              aria-hidden="true"
            ></div>
            <div className="max-w-md px-6 mx-auto sm:max-w-3xl lg:max-w-none lg:p-0">
              <div className="aspect-h-6 aspect-w-10 sm:aspect-h-1 sm:aspect-w-2 lg:aspect-w-1">
                <img
                  className="object-cover object-center shadow-2xl rounded-3xl"
                  // src="https://images.unsplash.com/photo-1620712943543-bcc4688e7485?q=80&w=934&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  src={cyborg}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="relative bg-gradient-to-r from-[#5745a2] to-[#29025f] lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center lg:rounded-3xl">
            <div
              className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block"
              aria-hidden="true"
            >
              <svg
                className="absolute transform bottom-full left-full -translate-x-2/3 translate-y-1/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-indigo-500"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
              <svg
                className="absolute transform top-full -translate-x-1/3 -translate-y-1/3 xl:-translate-y-1/2"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-indigo-500"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
            </div>
            <div className="relative max-w-md px-6 py-12 mx-auto space-y-6 sm:max-w-3xl sm:py-16 lg:col-span-6 lg:col-start-4 lg:max-w-none lg:p-0">
              <h2
                className="text-3xl font-bold tracking-tight text-white"
                id="join-heading"
              >
                Nerd Toolbox AI
              </h2>
              <p className="text-lg text-white">
                Be among the first to experience our platform's{" "}
                <Link
                  to="/ai-solutions"
                  className="font-bold text-transparent underline bg-gradient-to-r from-sky-200 to-blue-200 bg-clip-text"
                >
                  AI Solutions
                </Link>
                . Join our wait-list and receive early beta access once
                launched.&nbsp;
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, errors, touched, isValid }) => (
                  <Form className="flex flex-col gap-y-2 gap-x-3">
                    <input hidden type="text" id="to-trap-bot" />
                    <div className="flex flex-col">
                      <Field
                        type="email"
                        name="email"
                        id="email-input"
                        className="flex-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                      />
                      {errors.email && touched.email && (
                        <span className="text-sm font-semibold text-yellow-300">
                          {errors.email}
                        </span>
                      )}
                    </div>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="self-end px-3 py-2 mt-3 text-sm font-semibold text-indigo-700 bg-white rounded-md shadow-sm sm:justify-end sm:w-auto ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
                    >
                      Join Us
                    </button>
                  </Form>
                )}
              </Formik>
              {/* <Link
                className="block w-full px-5 py-3 text-base font-medium text-center text-indigo-700 bg-white border border-transparent rounded-md shadow-md hover:bg-gray-50 sm:inline-block sm:w-auto"
                to="/careers"
              >
                Explore open positions
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JoinBeta;
