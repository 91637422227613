/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import ScrollToTop from "../../common/ScrollToTop";

/**
 * This is a root component.
 *
 * Date: 22th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */
const PrivacyPolicy = () => {
  return (
    <div className="static top-0 bg-white bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]">
      <ScrollToTop />
      <div className="px-4 pt-3 pb-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="mx-auto max-w-[40rem] prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600">
          <div className="text-center">
            <h1 className="pb-2 text-5xl font-extrabold leading-tight text-gray-900 font-heading tracking-px-n">
              Privacy policy
            </h1>
            <p className="text-base font-medium leading-relaxed text-gray-600">
              Last updated on Apr 27th, 2024.
            </p>
          </div>
          <p className="pt-10 text-base font-medium leading-relaxed text-gray-500">
            This Privacy Policy describes how Nerd Toolbox ("we," "us," or
            "our") collects, uses, and discloses your personal information when
            you use our company website{" "}
            <a
              href="https://nerdtoolbox.com"
              target="_blank"
              rel="noreferrer"
              className="text-sky-600 hover:text-sky-500"
            >
              (https://nerdtoolbox.com)
            </a>{" "}
            (the "Site") and our Software as a Service (SaaS) application (the
            "Service").
          </p>
          <div>
            <h3 className="pt-5 text-2xl font-bold text-gray-800 ">
              Information We Collect
            </h3>
            <p className="pt-2 text-base leading-relaxed text-gray-500">
              We collect several different types of information for various
              purposes to improve our Service to you.
            </p>
            <p className="pt-2 text-base leading-relaxed text-gray-500">
              Information You Directly Provide: When you use the Service, you
              may provide us with certain personally identifiable information
              that can be used to contact or identify you ("Personal
              Information"). This may include, but is not limited to:
            </p>
            <div className="pt-2 text-base leading-relaxed text-gray-500">
              <span className="block">Name</span>
              <span className="block">Email address</span>
              <span className="block">Company name (optional)</span>
              <span className="block">Billing information (if applicable)</span>
              <span className="block">
                Information Collected Automatically: When you use the Service,
                we may automatically collect certain information about your
                usage ("Usage Data"). This Usage Data may include:
              </span>
              <span className="block">IP address</span>
              <span className="block">Browser type</span>
              <span className="block">Operating system</span>
              <span className="block">Referring URL</span>
              <span className="block">Pages visited</span>
              <span className="block">Time spent on those pages</span>
              <span className="block">
                Device identifiers and usage statistics
              </span>
              <span className="block">Use of Your Information</span>
            </div>
          </div>
          <div className="pt-2 text-base leading-relaxed text-gray-500">
            <h3 className="pt-2 text-2xl font-bold text-gray-800 ">
              We use the information we collect for various purposes:
            </h3>
            <span className="block pt-2">
              To provide and maintain the Service
            </span>
            <span className="block">
              To improve and personalize the Service
            </span>
            <span className="block">
              To send you marketing and promotional communications (with your
              consent)
            </span>
            <span className="block">
              To respond to your inquiries and requests
            </span>
            <span className="block">
              To fulfill any other purposes for which you provide the
              information
            </span>
            <span className="block">To comply with legal obligations</span>
            <span className="block">Sharing Your Information</span>

            <p className="pt-2 text-base leading-relaxed text-gray-500">
              We may share your information with third-party service providers
              who help us operate and improve the Service. These third parties
              are obligated to protect your information and use it only for the
              purposes we specify.
            </p>
            <p className="pt-2 text-base leading-relaxed text-gray-500">
              We may also disclose your information if required by law or to
              protect the rights, property, or safety of ourselves or others.
            </p>
          </div>
          <div className="pt-2 text-base leading-relaxed text-gray-500">
            <h3 className="pt-2 text-2xl font-bold text-gray-800 ">
              Your Rights
            </h3>
            <p>
              Depending on your location, you may have certain rights regarding
              your personal information. These rights may include:
            </p>
            <span className="block">
              The right to access your personal information
            </span>
            <span className="block">
              The right to rectify inaccurate personal information
            </span>
            <span className="block">
              The right to request the erasure of your personal information
            </span>
            <span className="block">
              The right to object to the processing of your personal information
            </span>
            <span className="block">
              You can exercise these rights by contacting us as described below.
            </span>
          </div>
          <div className="pt-2 text-base leading-relaxed text-gray-500">
            <h3 className="pt-2 text-2xl font-bold text-gray-800 ">
              Data Retention
            </h3>
            <p className="pt-2 text-base leading-relaxed text-gray-500">
              We will retain your personal information for as long as necessary
              to provide you with the Service and comply with our legal
              obligations. We will then delete your personal information in a
              secure manner.
            </p>
          </div>
          <div className="pt-2 text-base leading-relaxed text-gray-500">
            <h3 className="pt-2 text-2xl font-bold text-gray-800 ">
              Cookies and Tracking Technologies
            </h3>
            <p className="pt-2 text-base leading-relaxed text-gray-500">
              We use cookies and other tracking technologies to track the
              activity on our Service and hold certain information. Cookies are
              files with a small amount of data which may include an anonymous
              unique identifier. Cookies are sent to your browser from a website
              and stored on your device. Tracking technologies are also used
              such as beacons, scripts, and tags to collect and track
              information and to improve and analyze our Service.
            </p>
            <p className="pt-2 text-base leading-relaxed text-gray-500">
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
          </div>
          <div className="pt-2 text-base leading-relaxed text-gray-500">
            <h3 className="pt-2 text-2xl font-bold text-gray-800 ">
              Children's Privacy
            </h3>
            <p className="pt-2 text-base leading-relaxed text-gray-500">
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personal information from children under 13. If
              you are a parent or guardian and you are aware that your child has
              provided us with personal information, please contact us. If we
              become aware that we have collected personal information from a
              child under 13, we will delete such information from our servers.
            </p>
          </div>
          <div className="pt-2 text-base leading-relaxed text-gray-500">
            <h3 className="pt-2 text-2xl font-bold text-gray-800 ">
              Changes to this Privacy Policy
            </h3>
            <p className="pt-2 text-base leading-relaxed text-gray-500">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
          </div>
          <div className="pt-2 text-base leading-relaxed text-gray-500">
            <h3 className="pt-2 text-2xl font-bold text-gray-800 ">
              Contact Us
            </h3>
            <p className="pt-2 text-base leading-relaxed text-gray-500">
              If you have any questions about this Privacy Policy, please
              contact us:
            </p>
            <p className="pt-2 text-base leading-relaxed text-gray-500">
              By email:{" "}
              <a
                href="mailto:admin@nerdtoolbox.com"
                className="text-sky-600 hover:text-sky-500"
              >
                admin@nerdtoolbox.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
