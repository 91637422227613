/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import { useEffect } from "react";
import { aiSolutionsState } from "../../common/InitialState";
import ScrollToTop from "../../common/ScrollToTop";

/**
 * This is a root component.
 *
 * Date: 22th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */

const AiSolutions = () => {
  const aiSolutions = aiSolutionsState;
  useEffect(() => {
    document.title = "AI-Solutions";
  }, []);

  return (
    <div className="bg-gray-900">
      <ScrollToTop />
      <div className="px-4 py-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="pt-3 bg-gray-900">
          {aiSolutions.map((each, i) => (
            <div id={each.divId} key={(each.divId + i).toString()}>
              {i !== 0 && (
                <div className="px-6 my-10 border-t lg:px-8 border-white/50"></div>
              )}
              <div className="px-6 mx-auto max-w-7xl lg:px-8">
                <div className="max-w-2xl mx-auto sm:text-center">
                  {/* <h2 className="text-base font-semibold leading-7 text-indigo-400">
                    Nerd ToolBox AI
                  </h2> */}
                  <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    {each.title}
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-300">
                    {each.description}
                  </p>
                </div>
              </div>
              <div className="relative pt-16 overflow-hidden">
                <div className="px-6 mx-auto max-w-7xl lg:px-8">
                  <img
                    src={each.image}
                    alt="App screenshot"
                    className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
                    width="2432"
                    height="1442"
                  />
                  <div className="relative" aria-hidden="true">
                    <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]"></div>
                  </div>
                </div>
              </div>
              <div className="px-6 mx-auto mt-16 max-w-7xl sm:mt-20 md:mt-24 lg:px-8">
                <dl className="grid max-w-2xl grid-cols-1 mx-auto text-base leading-7 text-gray-300 gap-x-6 gap-y-10 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                  {each.bulletPoints.map((feature, i) => (
                    <div
                      className="relative pl-9"
                      key={(feature.name + i).toString()}
                    >
                      <dt className="inline font-semibold text-white">
                        <div className="absolute w-5 h-5 text-indigo-500 left-1 top-1">
                          &#128995;
                        </div>
                        {feature.name}
                      </dt>
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AiSolutions;
