/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import { Link } from "react-router-dom";
import ScrollToTop from "../../common/ScrollToTop";

/**
 * This is a root component.
 *
 * Date: 22th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */

const HomePage = () => {
  return (
    <div className="relative">
      <ScrollToTop />
      <img
        src="https://images.unsplash.com/photo-1626908013351-800ddd734b8a?q=80&w=1372&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&fit=crop&w=2830&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
        alt=""
        className="absolute inset-0 object-cover w-full h-full -z-10"
      />
      <div
        className="absolute inset-x-0 overflow-hidden -top-40 -z-10 transform-gpu blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#5b779e] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
      <div className="max-w-2xl py-16 mx-10 sm:mx-5 lg:mx-auto md:mx-auto sm:py-32 lg:py-48">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Our{" "}
            <span className="text-transparent bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text">
              Platform{" "}
            </span>
            provides{" "}
            <span className="text-transparent bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text">
              AI Tools{" "}
            </span>
            built by and for the{" "}
            <span className="text-transparent bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text">
              Community
            </span>
          </h1>
          <div className="mt-5">
            <Link
              to="/ai-solutions"
              className="relative px-0 py-1 mx-auto text-sm leading-6 text-gray-400 rounded-full sm:px-3 ring-1 ring-white/30 hover:ring-white/50"
            >
              Learn more about our{" "}
              <span className="font-semibold text-transparent bg-gradient-to-r from-violet-400 to-indigo-600 bg-clip-text">
                Solutions &rarr;
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomePage;
