/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { jobOpeningsState } from "../../common/InitialState";
import ScrollToTop from "../../common/ScrollToTop";
/**
 * This is a root component.
 *
 * Date: 22th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */

const Career = () => {
  useEffect(() => {
    document.title = "Careers";
  }, []);

  const initialValues = {
    applicant_input: "",
    resume: null as File | null,
  };

  const fileRef = useRef<HTMLInputElement>(null);

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const modalRef = useRef<HTMLDivElement | null>();

  const [jobTitle, setJobTitle] = useState<string>("");

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsFormOpen(false);
        setJobTitle("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const jobOpenings = jobOpeningsState;

  const toggleModal = () => {
    setIsFormOpen(!isFormOpen);
  };

  const validationSchema = Yup.object().shape({
    applicant_input: Yup.string()
      .required("Input is required")
      .matches(/^[A-Za-z0-9 ?;:\-.'@,\s]+$/g, "Please enter a valid input"),
    resume: Yup.mixed()
      .required("File selection is required")
      .test("is-valid-type", "Only pdf file is allowed", (value) => {
        const file = value as File[];
        if (!file || file.length === 0) return false;
        const extension = file[0].name
          ? file[0].name.split(".").pop()?.toLowerCase()
          : undefined;
        return extension === "pdf";
      })
      .test("is-valid-size", "Max allowed file size is 5MB", (value) => {
        const file = value as File[];
        if (!file || file.length === 0) return false;
        return file[0].size <= 5 * 1024 * 1024;
      }),
  });

  const onSubmit = async (values: any, { resetForm }: any) => {
    const formData = new FormData();
    formData.append("job_position", jobTitle);
    formData.append("applicant_input", values.applicant_input);
    formData.append("resume", values.resume[0]);
    console.log(formData);

    await fetch(process.env.REACT_APP_BACKENDURL + "/job-applicant", {
      method: "post",
      body: formData,
    })
      .then((result) => {
        if (!result.ok) {
          toast.error("Server didn't able to process the request.");
          throw Error("Error, Server didn't able to process the request.");
        }
        return result.json();
      })
      .then((result) => {
        toast.success(result.data.message);
        if (fileRef.current) {
          fileRef.current.value = "";
        }
        resetForm();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // bg-blueGray-50
  return (
    <div className="static top-0 bg-white bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]">
      <ScrollToTop />
      <div className="px-4 pt-3 pb-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="items-center py-3 text-center">
          <p className="text-sm font-bold text-indigo-600 uppercase tracking-px">
            Join with us
          </p>
          <p className="pb-2 text-5xl font-bold leading-tight font-heading tracking-px-n">
            Open Positions
          </p>
          <p className="text-base font-medium leading-relaxed text-gray-600">
            Talent fuels our future. Be a part of it.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2 lg:flex-row">
            {jobOpenings.map((each, idx) => {
              return (
                <button
                  key={(idx * 10).toString()}
                  onClick={() => {
                    toggleModal();
                    setJobTitle(each.title);
                  }}
                >
                  <div className="flex flex-col p-5 text-left border cursor-pointer gap-y-4 rounded-xl hover:shadow-lg bg-opacity-80 bg-inherit">
                    <h3 className="text-xl font-bold leading-snug font-heading">
                      {each.title}
                    </h3>
                    <p className="leading-relaxed text-gray-500">
                      {each.description}
                    </p>
                    <div className="flex flex-col items-center justify-between sm:items-end className sm:flex-row">
                      <div className="flex items-center p-3 gap-x-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        <p className="lg:text-nowrap">{each.jobType}</p>
                      </div>
                      <div className="flex items-center p-3 gap-x-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                          />
                        </svg>
                        <p className="lg:text-nowrap">{each.location}</p>
                      </div>
                      <div className="p-2 text-indigo-500 hover:text-indigo-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
          <div className="text-center">
            <span>
              If you don't find a job matching your profile, you can still
              apply&nbsp;
            </span>
            <span
              className="text-red-600 cursor-pointer hover:underline"
              onClick={toggleModal}
            >
              here.
            </span>
          </div>
        </div>
        {isFormOpen && (
          <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-75 z-90">
            <div
              className="w-full max-w-md p-5 bg-white rounded-lg shadow-md"
              ref={(node) => (modalRef.current = node)}
            >
              <div className="flex items-center justify-between mb-6">
                <h5 className="text-xl font-medium text-center">
                  {jobTitle
                    ? `Apply for a ${jobTitle} Role`
                    : "Apply for an Open Position"}
                </h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-7 h-7 hover:cursor-pointer"
                  onClick={(e) => setIsFormOpen(false)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched, setFieldValue, isSubmitting }) => (
                  <Form id="job-form" className="flex flex-col gap-4">
                    <div>
                      <label
                        htmlFor="why-your-a-good-fit"
                        className="block mb-3 text-sm font-medium"
                      >
                        Explain, why you are a good fit?
                      </label>
                      <Field
                        as="textarea"
                        name="applicant_input"
                        id="why-your-a-good-fit"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        rows={8}
                        maxLength={900}
                        placeholder="Tell us about your interest and how your skills and experience align."
                        aria-describedby="hs-textarea-helper-text"
                      />
                      {errors.applicant_input && touched.applicant_input && (
                        <div className="text-sm font-semibold text-red-500">
                          {errors.applicant_input}
                        </div>
                      )}
                    </div>
                    <div className="flex items-center gap-x-2">
                      <label
                        htmlFor="resume-file-input"
                        className="block text-sm font-medium whitespace-nowrap"
                      >
                        Attach Resume:
                      </label>
                      <div className="w-full">
                        <label htmlFor="resume-file-input" className="sr-only">
                          Add Resume
                        </label>
                        <input
                          ref={fileRef}
                          type="file"
                          name="resume"
                          id="resume-file-input"
                          accept="application/pdf"
                          className="w-full text-sm text-gray-900 border-0 rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 disabled:opacity-50 disabled:pointer-events-none file:border-0 file:me-4 file:py-2 file:px-2 file:text-gray-900"
                          onChange={(event) => {
                            setFieldValue(
                              "resume",
                              event?.currentTarget?.files
                            );
                          }}
                        />
                        {errors.resume && (
                          <div className="text-sm font-semibold text-red-500">
                            {errors.resume}
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="w-full px-4 py-2 text-white rounded-lg bg-violet-900 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
                    >
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Career;
