/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import { useEffect } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../common/ScrollToTop";
import anonymousProfile from "../../images/anonymous_profile.png";
import jasonDp from "../../images/jason_ausborn_dp.jpg";

/**
 * This is a header component.
 *
 * Date: 22th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */

const WhoWeAre = () => {
  useEffect(() => {
    document.title = "Who-We-Are";
  }, []);
  return (
    <div className="bg-[url('https://images.unsplash.com/photo-1626908013351-800ddd734b8a?q=80&w=1372&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&fit=crop&w=2830&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply')]">
      <main className="relative isolate">
        <ScrollToTop />
        <div
          className="absolute inset-x-0 flex justify-center overflow-hidden top-4 transform-gpu blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
            style={{
              clipPath:
                "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
            }}
          ></div>
        </div>

        <div className="pt-10 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img
            loading="lazy"
            src="https://images.unsplash.com/photo-1573297627466-6bed413a43f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2894&q=80"
            alt=""
            className="aspect-[9/4] w-full object-cover xl:rounded-3xl"
          />
        </div>

        <div className="px-6 mx-auto mt-20 max-w-7xl sm:mt-3 lg:px-8">
          <div className="w-full mx-auto md:mx-6 lg:mx-0">
            <p className="mt-4 text-lg leading-8 text-gray-300">
              <span className="font-semibold">Nerd Toolbox</span>, a
              remote-first Denver, CO based startup is currently bringing tech
              wizards together to solve amazing AI challenges.
            </p>
          </div>
          <div className="px-6 my-10 border-t lg:px-8 border-white/50"></div>
          <div className="max-w-2xl mx-auto lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Mission
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Empowering innovation with responsible AI. We build ethical,
              transparent AI Tools that put users in control.
            </p>
          </div>
          <div className="px-6 my-10 border-t lg:px-8 border-white/50"></div>
          <div className="max-w-2xl mx-auto lg:mx-0 ">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Values
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              We're more than just an AI platform – we're building a future
              where anyone can leverage the power of AI for good. Our core
              values focus on accessibility, human-centered design, and
              empowering everyone to learn and create with AI. We believe in
              collaboration, ethical development, and building tools that tackle
              real-world challenges. Join us as we make AI accessible and
              empower the next generation of innovators and entrepreneurs.
            </p>
          </div>
          <dl className="grid max-w-2xl grid-cols-1 gap-8 mx-auto mt-16 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <div className="absolute w-5 h-5 text-indigo-500 left-1 top-1">
                  &#128995;
                </div>
                Democratizing AI:{" "}
              </dt>
              <dd className="inline">
                We believe AI shouldn't be a privilege for the tech giants.
                We're building tools that are accessible, understandable, and
                empower everyone to leverage the power of AI.
              </dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <div className="absolute w-5 h-5 text-indigo-500 left-1 top-1">
                  &#128995;
                </div>
                Human-Centered Innovation:{" "}
              </dt>
              <dd className="inline">
                We're obsessed with creating AI that enhances the human
                experience, not replaces it. We design with empathy and
                prioritize tools that make life easier, more creative, and more
                efficient.
              </dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <div className="absolute w-5 h-5 text-indigo-500 left-1 top-1">
                  &#128995;
                </div>
                Learning for All:{" "}
              </dt>
              <dd className="inline">
                We're building a future where AI isn't a black box. We value
                transparency and education, helping users understand how AI
                works and fostering a culture of continuous learning.
              </dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <div className="absolute w-5 h-5 text-indigo-500 left-1 top-1">
                  &#128995;
                </div>
                Building a Better World:{" "}
              </dt>
              <dd className="inline">
                We're passionate about using AI for good. We'll prioritize
                ethical development, fight bias, and create tools that tackle
                real-world challenges for a more sustainable and positive
                future.
              </dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <div className="absolute w-5 h-5 text-indigo-500 left-1 top-1">
                  &#128995;
                </div>
                Collaboration is Key:{" "}
              </dt>
              <dd className="inline">
                We believe in the power of community. We'll build an open
                platform that encourages collaboration, knowledge sharing, and
                fosters a vibrant ecosystem of AI creators and users.
              </dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <div className="absolute w-5 h-5 text-indigo-500 left-1 top-1">
                  &#128995;
                </div>
                Side Hustle Squad:{" "}
              </dt>
              <dd className="inline">
                We understand the gig economy and the drive to create something
                new. We'll build tools that empower anyone to turn their ideas
                into reality, fueling the next generation of innovators and
                entrepreneurs.
              </dd>
            </div>
          </dl>

          <div className="px-6 my-10 border-t lg:px-8 border-white/50"></div>
          <div className="max-w-2xl mx-auto">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Meet our team
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-400">
              We're a tight-knit team of tech wizards at Nerd Toolbox. Our agile
              approach and combined expertise allow us to move fast and build
              innovative AI solutions.
            </p>
          </div>
          <ul className="grid max-w-2xl grid-cols-1 gap-6 mx-auto mt-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
            <li className="px-8 py-10 bg-gray-800 rounded-2xl">
              <img
                loading="lazy"
                className="w-48 h-48 mx-auto rounded-full md:h-56 md:w-56"
                src={jasonDp}
                alt=""
              />
              <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white">
                Jason Ausborn
              </h3>
              <p className="text-sm leading-6 text-gray-400">Founder, CEO</p>
              <ul className="flex justify-center mt-6 gap-x-6">
                {/* <li>
                  <a href="#" className="text-gray-400 hover:text-gray-300">
                    <span className="sr-only">X</span>
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                    </svg>
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://linkedin.com/in/jayzon"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-400 hover:text-gray-300"
                  >
                    <span className="sr-only">LinkedIn</span>
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </li>
            <li className="px-8 py-10 bg-gray-800 rounded-2xl">
              <img
                loading="lazy"
                className="w-48 h-48 mx-auto rounded-full md:h-56 md:w-56"
                src={anonymousProfile}
                alt=""
              />
              <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white">
                Now Hiring
              </h3>
              <p className="text-sm leading-6 text-gray-400">
                Full-Stack Developer
              </p>
              {/* <ul role="list" className="flex justify-center mt-6 gap-x-6">
                <li>
                  <a href="#" className="text-gray-400 hover:text-gray-300">
                    <span className="sr-only">X</span>
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com/in/jayzon"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-400 hover:text-gray-300"
                  >
                    <span className="sr-only">LinkedIn</span>
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul> */}
            </li>
            <li className="px-8 py-10 bg-gray-800 rounded-2xl">
              <img
                loading="lazy"
                className="w-48 h-48 mx-auto rounded-full md:h-56 md:w-56"
                src={anonymousProfile}
                alt=""
              />
              <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white">
                Now Hiring
              </h3>
              <p className="text-sm leading-6 text-gray-400">
                Technical Lead (AI)
              </p>
              {/* <ul role="list" className="flex justify-center mt-6 gap-x-6">
                <li>
                  <a href="#" className="text-gray-400 hover:text-gray-300">
                    <span className="sr-only">X</span>
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com/in/jayzon"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-400 hover:text-gray-300"
                  >
                    <span className="sr-only">LinkedIn</span>
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul> */}
            </li>
          </ul>
          <div className="px-6 mt-10 border-t lg:px-8 border-white/50"></div>
        </div>

        <div className="relative pb-10 mt-10 isolate -z-10 sm:mt-10">
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex flex-col max-w-2xl gap-16 px-6 py-16 mx-auto bg-white/5 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
              <img
                loading="lazy"
                className="flex-none object-cover w-full shadow-xl h-96 rounded-2xl lg:aspect-square lg:h-auto lg:max-w-sm"
                src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                alt=""
              />
              <div className="flex-auto w-full">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  Join our team
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  We believe in finding the right talent, and your unique skills
                  could be just what we're looking for. Don't hesitate to take
                  the next step – we can't wait to hear from you!
                </p>
                <ul
                  role="list"
                  className="grid grid-cols-1 mt-10 text-base leading-7 text-white gap-x-8 gap-y-3 sm:grid-cols-2"
                >
                  <li className="flex gap-x-3">
                    <svg
                      className="flex-none w-5 h-7"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Competitive salaries
                  </li>
                  <li className="flex gap-x-3">
                    <svg
                      className="flex-none w-5 h-7"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Flexible work hours
                  </li>
                  <li className="flex gap-x-3">
                    <svg
                      className="flex-none w-5 h-7"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Remote-First workspace
                  </li>
                  <li className="flex gap-x-3">
                    <svg
                      className="flex-none w-5 h-7"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                    A great work environment
                  </li>
                </ul>
                <div className="flex mt-10">
                  <Link
                    to="/careers"
                    className="text-sm font-semibold leading-6 text-indigo-400"
                  >
                    See our job postings <span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="absolute inset-x-0 flex justify-center overflow-hidden transform-gpu blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
              style={{
                clipPath:
                  "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
              }}
            ></div>
          </div> */}
        </div>
      </main>
    </div>
  );
};
export default WhoWeAre;
