/*
 * Copyright (C) 2024-2025 nerdtoolbox.com, Inc. All Rights Reserved.
 *
 * This software is the confidential and proprietary information of nerdtoolbox.com, Inc.
 * ("Confidential Information").  You shall not disclose such Confidential Information and
 * shall use it only in accordance with the terms of the license agreement you entered into
 * with nerdtoolbox.com, Inc.
 */

import { Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import ScrollToTop from "../../common/ScrollToTop";
import { ContactUsForm } from "../../common/Types";
/**
 * This is a header component.
 *
 * Date: 22th Mar 2024
 * @version 0.1.0
 * @author G Biswajeet Achary
 */

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact-Us";
  }, []);
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    subject: "Other",
    message: "",
  };

  const subjects = ["Support", "Partnership Inquiry", "Other"];

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required")
      .matches(/[a-zA-Z0-9 ]/g, "Please enter a valid First Name"),
    last_name: Yup.string()
      .required("Last Name is required")
      .matches(/[a-zA-Z0-9 ]/g, "Please enter a valid First Name"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,9})/g,
        "Please enter a valid email"
      ),
    phone: Yup.string().matches(
      /^\+(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/g,
      "Please enter a valid phone number with country code e.g: +421234567890"
    ),
    subject: Yup.string()
      .required("Subject selection is required")
      .oneOf(
        ["Support", "Partnership Inquiry", "Other"],
        "Subject selection is required"
      ),
    message: Yup.string()
      .required("Message is required")
      .min(25, "Message must be at least 25 characters")
      .matches(
        /^[A-Za-z0-9 ?;:\-.'@,\s]+$/g,
        "Please don't any use special character except(':', ';', '.', '?', '/', '@', ',')"
      ),
  });

  const onSubmit = async (values: ContactUsForm, { resetForm }: any) => {
    await fetch(process.env.REACT_APP_BACKENDURL + "/contact-us", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((result) => {
        if (!result.ok) {
          toast.error(
            "Sorry, something has gone wrong. Please check the input and or try again later."
          );
          throw Error("Error, Server didn't able to process the request.");
        }
        return result.json();
      })
      .then((result) => {
        toast.success(result.data.message);
        resetForm();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  /*const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });*/

  return (
    <div className="relative top-0  bg-white bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]">
      <div className="px-4 pt-3 pb-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <ScrollToTop />

        <div className="relative bg-white">
          <div className="lg:absolute lg:inset-0 lg:left-1/2">
            <img
              loading="lazy"
              className="object-cover w-full h-64 bg-gray-50 sm:h-80 lg:absolute lg:h-full"
              src="https://images.unsplash.com/photo-1542435503-956c469947f6?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>
          <div className="pt-3 pb-6 sm:pb-9 sm:pt-6 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-9">
            <div className="px-6 lg:px-8">
              <div className="max-w-xl mx-auto lg:mx-0 lg:max-w-lg">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Contact Us
                </h2>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                  Have a question or need some assistance? Whether you're
                  seeking technical support, interested in exploring partnership
                  opportunities, or have something else on your mind, feel free
                  to reach out using the form below.
                </p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form className="mt-16">
                      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                          <label
                            htmlFor="input-firstname-label"
                            className="block text-sm font-semibold leading-6 text-gray-900"
                          >
                            First name
                          </label>
                          <div className="mt-2.5">
                            <Field
                              type="text"
                              name="first_name"
                              id="input-firstname-label"
                              autoComplete="given-name"
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors.first_name && touched.first_name && (
                              <div className="text-sm font-semibold text-red-500">
                                {errors.first_name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="input-lastname-label"
                            className="block text-sm font-semibold leading-6 text-gray-900"
                          >
                            Last name
                          </label>
                          <div className="mt-2.5">
                            <Field
                              type="text"
                              id="input-lastname-label"
                              name="last_name"
                              autoComplete="family-name"
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors.last_name && touched.last_name && (
                              <div className="text-sm font-semibold text-red-500">
                                {errors.last_name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="flex justify-between text-sm leading-6">
                            <label
                              htmlFor="input-email-label"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              Email
                            </label>
                            <p id="email-description" className="text-gray-400">
                              We'll never share your details.
                            </p>
                          </div>
                          <div className="mt-2.5">
                            <Field
                              id="input-email-label"
                              name="email"
                              type="email"
                              autoComplete="email"
                              aria-describedby="email-description"
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors.email && touched.email && (
                              <div className="text-sm font-semibold text-red-500">
                                {errors.email}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="flex justify-between text-sm leading-6">
                            <label
                              htmlFor="input-phoneno-label"
                              className="block font-semibold text-gray-900"
                            >
                              Phone
                            </label>
                            <p id="phone-description" className="text-gray-400">
                              Optional
                            </p>
                          </div>
                          <div className="mt-2.5">
                            <Field
                              type="tel"
                              name="phone"
                              id="input-phoneno-label"
                              autoComplete="tel"
                              aria-describedby="phone-description"
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors.phone && touched.phone && (
                              <div className="text-sm font-semibold text-red-500 ">
                                {errors.phone}
                              </div>
                            )}
                          </div>
                        </div>
                        <fieldset className="sm:col-span-2">
                          <legend className="block text-sm font-semibold leading-6 text-gray-900">
                            Subject
                          </legend>
                          <div className="flex flex-wrap mt-4 text-sm leading-6 text-gray-600 gap-x-5">
                            {subjects.map((each, indx) => {
                              return (
                                <div
                                  className="flex gap-x-2.5"
                                  key={indx.toString() + each}
                                >
                                  <Field
                                    id={"subject-radio-group" + indx}
                                    name="subject"
                                    type="radio"
                                    value={each}
                                    className="w-4 h-4 mt-1 text-indigo-600 border-gray-300 shadow-sm focus:ring-indigo-600"
                                  />
                                  <label htmlFor={"subject-radio-group" + indx}>
                                    {each}
                                  </label>
                                </div>
                              );
                            })}
                            {errors.subject && touched.subject && (
                              <div className="text-sm font-semibold text-red-500">
                                {errors.subject}
                              </div>
                            )}
                          </div>
                        </fieldset>
                        <div className="sm:col-span-2">
                          <div className="flex justify-between text-sm leading-6">
                            <label
                              htmlFor="input-message-label"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              Message
                            </label>
                            <p
                              id="message-description"
                              className="text-gray-400"
                            >
                              Max 500 characters
                            </p>
                          </div>
                          <div className="mt-2.5">
                            <Field
                              as="textarea"
                              id="input-message-label"
                              name="message"
                              maxLength={900}
                              rows={4}
                              aria-describedby="message-description"
                              className="block a w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors.message && touched.message && (
                              <div className="text-sm font-semibold text-red-500">
                                {errors.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end pt-8 mt-10 border-t border-gray-900/10">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="rounded-md bg-violet-900 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-violet-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed"
                        >
                          Send message
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
